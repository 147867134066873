import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Script from 'next/script'
import 'public/static/css/normalize.css'
import 'public/static/css/bootstrap.min.css'
import 'public/static/css/owl.carousel.css'
import 'public/static/css/magnific-popup.css'
import 'public/static/css/main.css'

import { register } from 'utils/sw'
import { GA_TRACKING_ID } from 'config'

export default function App ({ Component, pageProps, err }) {
  useEffect(() => {
    register()
  }, [])

  return (
    <>
      <Component {...pageProps} err={err} />
      <Script src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `
        }} />
      {/*
      <Script strategy="afterInteractive" src="static/js/owl.carousel.min.js" />
      <Script strategy="afterInteractive" src="static/js/validator.js" />
      <Script strategy="afterInteractive" src="static/js/jquery.shuffle.min.js" />
      <Script strategy="afterInteractive" src="static/js/jquery.magnific-popup.min.js" />

      <Script strategy="afterInteractive" src="static/js/modernizr.custom.js" />
      <Script src="static/js/jquery-3.5.1.min.js" />
      <Script strategy="afterInteractive" src="static/js/imagesloaded.pkgd.min.js" />
      <Script strategy="afterInteractive" src="static/js/bootstrap.min.js" />
      <Script strategy="afterInteractive" src="static/js/masonry.pkgd.min.js" />
      <Script strategy="afterInteractive" src="static/js/jquery.shuffle.min.js" />
      <Script strategy="afterInteractive" src="static/js/main.js" />
      */ }
    </>
  )
}

App.propTypes = {
  Component: PropTypes.any,
  err: PropTypes.any,
  pageProps: PropTypes.object
}
